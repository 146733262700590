import Vue from 'vue'
// import Buefy from 'buefy'

export default {
  browserCompatible: false,
  browserPermission: null,
  lastNotificationTime: null,
  canBeNotified: true,
  sounds: {
    _path: process.env.VUE_APP_ASSETS_URL + '/sounds/',
    newRequest: '02 Alerts and Notifications/notification_simple-02.wav',
    newMessage: '02 Alerts and Notifications/notification_simple-01.wav'
  },
  notificationsMinInterval: 0, // min-time between 2 notifications (spam)

  install (Vue, options) {
    this.lastNotificationTime = null
    this.permission = null
    Vue.prototype.$browserPush = this

    const route = window.location.pathname
    // abort on these routes
    if (route.startsWith('/reset-password') || route.startsWith('/login') || route.startsWith('/password') || route.startsWith('/forgot-password') || route.startsWith('/validate-account')) {
      return false
    }

    /*
    if (!this.checkBrowserCompatibility()) {
      Vue.prototype.$buefy.dialog.alert({
        title: 'Navigateur non-compatible',
        message: 'Votre navigateur ne prend pas en charge les notifications.<br>Merci d\'en utiliser un autre comme <a href="https://www.mozilla.org/fr/firefox/new/" target="_blank">Firefox</a> ou <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>',
        type: 'is-danger',
        hasIcon: true,
        icon: 'exclamation-triangle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    }

    if (this.browserCompatible) {
      this.checkBrowserPermission(true)
    }
    */
  },

  checkBrowserCompatibility () {
    this.browserCompatible = 'Notification' in window
    return this.browserCompatible
  },

  checkBrowserPermission (ask = false) {
    this.browserPermission = Notification.permission
    console.log('this.browserPermission', this.browserPermission)

    // if browser permission has never been asked
    if (this.browserPermission !== 'granted' &&
        this.browserPermission !== 'denied') {
      this.askForBrowserPermission()
    }

    // if browser permission has been previously denied
    if (this.browserPermission === 'denied') {
      Vue.prototype.$buefy.dialog.confirm({
        title: 'Autorisation des notifications',
        message: 'Vous avez précédemment refusé les notifications.<br>Vous pouvez les ré-autoriser en cliquant ci-dessous :',
        cancelText: 'Annuler',
        confirmText: 'Demander les permissions',
        type: 'is-danger',
        hasIcon: true,
        icon: 'exclamation-triangle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
        onConfirm: () => this.askForBrowserPermission()
      })
    }
  },

  askForBrowserPermission () {
    console.log('askForBrowserPermission')
    Notification.requestPermission().then((permission) => {
      this.browserPermission = permission
      console.log('this.browserPermission', this.browserPermission)
    })
  },

  newRequest (request) {
    new Audio(this.sounds._path + this.sounds.newRequest).play()
    console.log('request', request)
    // eslint-disable-next-line
    new Notification('Nouvelle requête', {
      icon: process.env.VUE_APP_ASSETS_URL + 'images/icon.png',
      title: 'Nouvelle requête',
      body: `${request.initiator.firstname} ${request.initiator.lastname} <${request.initiator.email}>`
    })
  },

  newMessage () {
    new Audio(this.sounds._path + this.sounds.newMessage).play()
  }
}
