import axios from 'axios'
import store from '../store'
import Router from '../router'

const application = process.env.VUE_APP_APPLICATION

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'X-SOURCE': 'administration'
  }
})

http.interceptors.response.use(undefined, function (err) {
  if (err.response.status === 401) {
    store.dispatch('AUTH_LOGOUT').then(() => {
      Router.push('/login').catch(() => {})
    })
  }
  throw err
})

http.interceptors.request.use(function (config) {
  config.headers.Domain = store.getters.domain.uuid
  config.headers.Authorization = `Bearer ${store.getters.jwt}`
  config.headers.Application = application
  config.headers['X-DOMAIN'] = store.getters.domain.uuid
  config.headers['X-APPLICATION'] = application
  return config
}, function (error) {
  return Promise.reject(error)
})

export { http }
