import io from 'socket.io-client'
import store from '../store'
import { ToastProgrammatic as Toast } from 'buefy'

export const socket = io(`${process.env.VUE_APP_SOCKET_URL}`)

socket.on('connect', function () {
  store.dispatch('SOCKET_CONNECTION').then(() => {
    socket.emit('subscribe', { room: `${store.state.domain.uuid}/operators` })
  })
})

socket.on('disconnect', function (socket) {
  store.dispatch('SOCKET_DISCONNECTION').then(() => {
    Toast.open({
      message: 'Nous rencontrons des difficultés à conserver votre connexion au serveur de chat',
      duration: 3000,
      type: 'is-warning'
    })
  })
})
