import Vue from 'vue'
import Vuex from 'vuex'
import { http } from '../services/api.service'

// Modules
// import user from './modules/user'
import { socket } from '../services/socket.service'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem(`${process.env.VUE_APP_APPLICATION}-token`) || '',
    socketConnected: false,
    roles: [],
    domain: {
      uuid: localStorage.getItem(`${process.env.VUE_APP_APPLICATION}-domain`) || ''
    },
    user: {},
    myRequests: [],
    pendingRequests: [],
    application: {},
    chats: [],
    documents: []
  },
  getters: {
    isAuthenticated: state => !!state.token,
    jwt: state => state.token,
    socketDisconnected: (state) => !state.socketConnected,
    roles: (state) => state.roles,
    user: (state) => state.user,
    domain: (state) => state.domain,
    pendingRequests: state => state.pendingRequests,
    myRequests: state => state.myRequests,
    application: (state) => state.application,
    chats: (state) => state.chats,
    documents: (state) => state.documents
  },
  mutations: {
    AUTH_LOGIN: (state, { token }) => {
      state.token = token
    },
    AUTH_LOGOUT: (state) => {
      state.token = ''
      state.user = null
      state.roles = []
      state.domain = {}
    },
    SOCKET_CONNECTION: (state) => {
      state.socketConnected = true
    },
    SOCKET_DISCONNECTION: (state) => {
      state.socketConnected = false
    },
    SET_TOKEN: (state, token) => {
      state.token = token
      localStorage.setItem(`${process.env.VUE_APP_APPLICATION}-token`, token)
    },
    SET_SOCKET_STATUS: (state, status) => {
      state.socketConnected = status
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_DOMAIN: (state, domain) => {
      state.domain = domain
      localStorage.setItem(`${process.env.VUE_APP_APPLICATION}-domain`, domain.uuid)
    },
    SET_APPLICATION: (state, application) => {
      state.application = application
    },
    SET_MY_REQUESTS: (state, requests) => {
      state.myRequests = requests
    },
    SET_PENDING_REQUESTS: (state, requests) => {
      state.pendingRequests = requests
    },
    SET_MY_CHATS: (state, chats) => {
      state.chats = chats
    },
    SET_MY_DOCUMENTS: (state, documents) => {
      state.documents = documents
    }
  },
  actions: {
    AUTH_LOGIN: ({ commit, dispatch }, { url, payload }) => {
      return new Promise((resolve, reject) => {
        http.post(url, payload)
          .then(res => {
            const { token, domain } = res.data
            commit('SET_TOKEN', token)
            commit('SET_DOMAIN', domain)
            http.defaults.headers.Authorization = 'Bearer ' + token
            dispatch('FETCH_USER')
            dispatch('FETCH_ROLES')
            setTimeout(() => {
              resolve(res)
            }, 300)
          })
          .catch(function (err) {
            localStorage.removeItem(`${process.env.VUE_APP_APPLICATION}-token`)
            reject(err.response)
          })
      })
    },
    AUTH_LOGOUT: ({ commit }, user) => {
      return new Promise((resolve, reject) => {
        commit('AUTH_LOGOUT')
        localStorage.removeItem(`${process.env.VUE_APP_APPLICATION}-token`)
        localStorage.removeItem(`${process.env.VUE_APP_APPLICATION}-domain`)
        delete http.defaults.headers.common['Authorization']
        resolve()
      })
    },
    SOCKET_CONNECTION: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('SET_SOCKET_STATUS', true)
        resolve()
      })
    },
    SOCKET_DISCONNECTION: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('SET_SOCKET_STATUS', false)
        resolve()
      })
    },
    FETCH_USER: ({ commit }) => {
      http.get('/me/profile').then(({ data }) => {
        socket.emit('auth:login', { userUuid: data.uuid })
        commit('SET_USER', data)
      }).catch(err => {
        console.log(err)
      })
    },
    FETCH_DOMAIN: ({ commit }) => {
      http.get('/me/domain').then(res => {
        commit('SET_DOMAIN', res.data)
      }).catch(err => {
        console.log(err)
      })
    },
    FETCH_APPLICATION: ({ commit }) => {
      http.get(`/application/${process.env.VUE_APP_APPLICATION}`).then(res => {
        commit('SET_APPLICATION', res.data)
      }).catch(err => {
        console.log(err)
      })
    },
    FETCH_ROLES: ({ commit }) => {
      return http.get('/me/role').then(({ data }) => {
        commit('SET_ROLES', data)
      }).catch(err => {
        console.error(err)
      })
    },
    FETCH_PENDING_REQUESTS: ({ commit }) => {
      http.get('/request/pending').then(({ data }) => {
        commit('SET_PENDING_REQUESTS', data)
      }).catch(err => {
        console.log(err)
      })
    },
    FETCH_MY_REQUESTS: ({ commit }) => { // ! DEPRECATED
      http.get('/request/my').then(({ data }) => {
        commit('SET_MY_REQUESTS', data)
        data.map(request => socket.emit('subscribe', { room: `${request.uuid}` }))
      }).catch(err => {
        console.log(err)
      })
    },
    FETCH_MY_CHATS: ({ commit }) => {
      http.get('/chats/mine').then(({ data }) => {
        commit('SET_MY_CHATS', data)
        data.map(chat => socket.emit('subscribe', { room: `${chat.uuid}` }))
      }).catch(err => {
        console.log(err)
      })
    },
    FETCH_MY_DOCUMENTS: ({ commit }) => {
      http.get('/documents').then(({ data }) => {
        commit('SET_MY_DOCUMENTS', data)
      }).catch(err => {
        console.log(err)
      })
    }
  }
})
